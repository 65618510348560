const useBodyScrollLock = () => {
  const body = ref(document?.body)

  const isBodyHasScrollBar = computed(() => body.value.scrollHeight > window.innerHeight)

  const { isMobile } = useAppBreakpoints()

  const lock = () => {
    if (!isBodyHasScrollBar.value) {
      return
    }

    if (isMobile) {
      return
    }

    body.value.style.overflow = 'hidden'
    body.value.style.paddingRight = '15px'
  }

  const unlock = () => {
    if (!isBodyHasScrollBar.value) {
      return
    }

    if (isMobile) {
      return
    }

    body.value.style.overflow = 'auto'
    body.value.style.paddingRight = '0'
  }

  return {
    lock,
    unlock,
  }
}

export default useBodyScrollLock
